<template>
  <ERow class="text-decoration-none ml-2" align="center">
    <CameraStatusIcon class="mr-3" :camera="value" />
    <span class="subtitle">{{ value.name }}</span>
    <NuxtLink
      :to="
        isAdmin
          ? `/cameras#camera_dialog=true&camera_exid=${value.exid}`
          : `/v2/cameras/${value.exid}`
      "
      target="_blank"
    >
      <v-icon color="primary" class="mt-n1 ml-1" small>
        fas fa-external-link-alt
      </v-icon>
    </NuxtLink>
  </ERow>
</template>

<script>
import CameraStatusIcon from "@evercam/shared/components/CameraStatusIcon"

export default {
  name: "CameraRessource",
  components: {
    CameraStatusIcon,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
