export default [
  {
    value: "id",
    text: "Id",
    width: 40,
    sortable: true,
  },
  {
    value: "widgetType",
    text: "Widget type",
    width: 100,
    sortable: true,
  },
  {
    value: "ressources",
    text: "Resources",
    width: 210,
    sortable: false,
  },
  {
    value: "isPublic",
    text: "Public",
    width: 60,
  },
  {
    value: "allowedOrigin",
    text: "Allowed origin",
    width: 100,
  },
  {
    value: "user",
    text: "User",
    width: 150,
    sortable: false,
  },
  {
    value: "settings",
    text: "Settings",
    width: 340,
  },
  {
    value: "active",
    text: "Status",
    width: 60,
  },
  {
    value: "view",
    text: "View",
    align: "center",
    width: 50,
    sortable: false,
  },
  {
    value: "link",
    text: "Link",
    align: "center",
    width: 50,
    sortable: false,
  },
  {
    value: "actions",
    text: "Actions",
    align: "center",
    width: 50,
    sortable: false,
  },
  {
    value: "insertedAt",
    text: "Created at",
    width: 90,
  },
  {
    value: "updatedAt",
    text: "Updated at",
    width: 90,
  },
]
