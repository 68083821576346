<template>
  <v-container fluid class="pa-0">
    <ReportTable
      name="widgets"
      :headers="headers"
      :filter-fields="filters"
      :provider="getAllWidgets"
      :copyToClipboard="false"
      sortBy="insertedAt"
    >
      <template #item.active="{ item }">
        <span
          class="lighten-4 text--darken-2 pa-1 px-2 rounded-pill"
          :class="item.active ? 'success--text' : 'warning--text'"
          data-test-id="progress-photo-status"
        >
          {{ item.active ? "Active" : "Paused" }}
        </span>
      </template>
      <template #item.isPublic="{ item }">
        {{ item.isPublic ? "Yes" : "No" }}
      </template>
      <template #item.settings="{ item }">
        <WidgetSettings :id="item.id" :settings="item.settings" />
      </template>
      <template #item.ressources="{ item }">
        <div
          v-for="(value, key) in item.ressources"
          :key="`${item.id}-${key}`"
          :data-test-id="`widget-${key}-ressource`"
        >
          <component
            :is="ressourceComponents[key]"
            v-if="Boolean(value)"
            :value="value"
            :is-admin="true"
          />
        </div>
      </template>
      <template #item.user="{ item }">
        {{ item.user.firstname + " " + item.user.lastname }}
        <ImpersonateUserButton
          v-if="canImpersonateUsers"
          :email="item.user.email"
          target-route="/users/widgets"
        />
      </template>
      <template #item.view="{ item }">
        <v-btn
          small
          color="primary"
          data-test-id="edit-widget-btn"
          icon
          @click="openPreviewWidget(item)"
        >
          <v-icon x-small> fa fa-window-maximize </v-icon>
        </v-btn>
      </template>
      <template #item.link="{ item }">
        <v-btn
          small
          color="primary"
          data-test-id="show-widget-code-btn"
          icon
          @click="showWidgetLink(item)"
        >
          <v-icon color="primary" small> fas fa-link </v-icon>
        </v-btn>
      </template>
      <template #item.actions="{ item }">
        <v-btn
          v-if="canUpdateWidget"
          small
          color="primary"
          class="mr-3"
          data-test-id="pause-widget-toggle-btn"
          icon
          @click="changeWidgetState(item)"
        >
          <v-icon v-if="item.active" x-small> fa fa-pause </v-icon>
          <v-icon v-else x-small> fa fa-play </v-icon>
        </v-btn>
        <v-btn
          v-if="canDeleteWidget"
          small
          color="primary"
          icon
          @click="deleteWidget(item)"
        >
          <v-icon x-small> fa fa-trash-alt </v-icon>
        </v-btn>
      </template>
    </ReportTable>
    <WidgetCredentials
      :widget="shownCredentialsWidget"
      @close="shownCredentialsWidget = null"
    />
    <WidgetPreview :widget="previewedWidget" @close="previewedWidget = null" />
  </v-container>
</template>

<script lang="ts">
import ReportTable from "@/components/ReportTable"
import CameraRessource from "@evercam/shared/components/widgets/ressources/CameraRessource"
import ProjectRessource from "@evercam/shared/components/widgets/ressources/ProjectRessource"
import WidgetCredentials from "@/components/widgets/WidgetCredentials"
import WidgetPreview from "@/components/widgets/WidgetPreview"
import ImpersonateUserButton from "@/components/ImpersonateUserButton"
import WidgetSettings from "@/components/widgets/WidgetSettings.vue"
import { AdminApi } from "@evercam/shared/api/adminApi"
import widgetHeaders from "@/components/widgets/widgetHeaders"
import widgetFilters from "@/components/widgets/widgetFilters"
import { PERMISSIONS } from "@/constants/permissions"
import Vue from "vue"

export default Vue.extend({
  components: {
    ReportTable,
    CameraRessource,
    ProjectRessource,
    ImpersonateUserButton,
    WidgetCredentials,
    WidgetPreview,
    WidgetSettings,
  },
  data: () => ({
    headers: widgetHeaders,
    filters: widgetFilters,
    shownCredentialsWidget: null,
    previewedWidget: null,
    ressourceComponents: {
      camera: "CameraRessource",
      project: "ProjectRessource",
    },
  }),
  computed: {
    getAllWidgets() {
      return AdminApi.widgets.index
    },
    canImpersonateUsers() {
      return this.$permissions.user.can(PERMISSIONS.USERS.IMPERSONATE)
    },
    canUpdateWidget() {
      return this.$permissions.user.can(PERMISSIONS.WIDGETS.UPDATE)
    },
    canDeleteWidget() {
      return this.$permissions.user.can(PERMISSIONS.WIDGETS.DELETE)
    },
  },
  methods: {
    async changeWidgetState({ id, active }) {
      try {
        await AdminApi.widgets.update(id, { active: !active })
        this.$notifications.success(
          `Widget ${id} has been turned ${!active ? "active" : "inactive"}`
        )
      } catch (error) {
        this.$notifications.error({
          text: `${this.$t("content.widgets.edit_widget_failed")}`,
          error,
        })
      } finally {
        this.$root.$emit("refresh-report-table")
      }
    },
    async deleteWidget({ id }) {
      if (
        await this.$confirmDialog.open({
          title: this.$t("content.widgets.delete_widget_title").toString(),
          message: this.$t("content.widgets.delete_widget_message").toString(),
        })
      ) {
        try {
          await AdminApi.widgets.delete(id)
          this.$notifications.success(`Widget ${id} has been deleted`)
          this.$root.$emit("refresh-report-table")
        } catch (error) {
          this.$notifications.error({
            text: `${this.$t("content.widgets.delete_widget_failed")}`,
            error,
          })
        }
      }
    },
    async showWidgetLink(item) {
      try {
        this.shownCredentialsWidget = await AdminApi.widgets.show(item.id)
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.widgets.fetch_widget_failed").toString(),
          error,
        })
      }
    },
    async openPreviewWidget(item) {
      try {
        this.previewedWidget = await AdminApi.widgets.show(item.id)
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.widgets.fetch_widget_failed").toString(),
          error,
        })
      }
    },
  },
})
</script>

<style lang="scss" scoped></style>
