import { ActiveOptions, BooleanOptions } from "@/components/constants"
import { WidgetType } from "@evercam/shared/types"

export default {
  active: {
    component: "SelectSearchFilter",
    attributes: {
      items: [{ name: "All", value: "" }, ...ActiveOptions],
      value: "",
    },
  },
  isPublic: {
    component: "SelectSearchFilter",
    attributes: {
      items: [{ name: "All", value: "" }, ...BooleanOptions],
      value: "",
    },
  },
  widgetType: {
    component: "SelectSearchFilter",
    attributes: {
      items: [
        { name: "All", value: "" },
        ...Object.values(WidgetType).map((value) => ({
          name: value,
          value,
        })),
      ],
      value: "",
    },
  },
  ressources: {
    component: "TextFieldSearchFilter",
  },
  user: {
    component: "TextFieldSearchFilter",
  },
  allowedOrigin: {
    component: "TextFieldSearchFilter",
  },
}
