<template>
  <ERow class="settings-container">
    <v-chip
      v-for="(value, key) in slicedSettings"
      :key="`${id}-${key}`"
      class="ma-1"
      :data-test-id="`widget-${key}-setting`"
      small
      :color="value ? 'primary' : 'secondary'"
    >
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <ETruncatedDiv :width="100" v-bind="attrs" v-on="on">
            <span class="caption"> {{ key }} </span>
          </ETruncatedDiv>
        </template>
        <span class="caption"> {{ key }} </span>
      </v-tooltip>
    </v-chip>
    <span
      v-if="Object.keys(formatedSettings).length > maxElements"
      class="e-cursor-pointer e-text-primary more-button"
      @click="isCollapsed = !isCollapsed"
    >
      {{ isCollapsed ? "More" : "Less" }}
    </span>
  </ERow>
</template>

<script lang="ts">
import {
  CommonWidgetSettingsKeys,
  RecordingsWidgetSettingsKeys,
  LiveViewWidgetSettingsKeys,
  VideoWallWidgetSettingsKeys,
} from "@evercam/shared/types"
import { toTitleCase, formatTime } from "@evercam/shared/utils"

export default {
  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
    maxElements: {
      type: Number,
      default: 3,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isCollapsed: true,
    }
  },
  computed: {
    formatedSettings() {
      const settingUnitMappings = {
        [CommonWidgetSettingsKeys.Height]:
          CommonWidgetSettingsKeys.HeightResolution,
        [CommonWidgetSettingsKeys.Width]:
          CommonWidgetSettingsKeys.WidthResolution,
        [RecordingsWidgetSettingsKeys.Delay]:
          RecordingsWidgetSettingsKeys.DelayUnit,
      }

      return Object.entries(this.settings).reduce((acc, [key, value]) => {
        if (
          (key === RecordingsWidgetSettingsKeys.Delay &&
            !this.settings[RecordingsWidgetSettingsKeys.HasDelay]) ||
          (key === RecordingsWidgetSettingsKeys.HasDelay && value)
        ) {
          return acc
        }
        if (typeof value === "boolean") {
          acc[toTitleCase(key)] = value
        } else if (settingUnitMappings[key]) {
          acc[
            `${toTitleCase(key)}: ${value} ${
              this.settings[settingUnitMappings[key]]
            }`
          ] = true
        } else if (
          [
            VideoWallWidgetSettingsKeys.RefreshRate,
            LiveViewWidgetSettingsKeys.Refresh,
          ].includes(key)
        ) {
          acc[`${toTitleCase(key)}: ${formatTime(value)}`] = true
        } else if (key === VideoWallWidgetSettingsKeys.Preset) {
          const cameras = (value || []).map((c) => c.camera).join(", ")
          acc[`${toTitleCase(key)}: (Cameras: ${cameras})`] = true
        } else if (!Object.values(settingUnitMappings).includes(key)) {
          acc[`${toTitleCase(key)}: ${value}`] = true
        }

        return acc
      }, {})
    },
    slicedSettings() {
      if (this.isCollapsed) {
        return Object.fromEntries(
          Object.entries(this.formatedSettings).slice(0, this.maxElements)
        )
      }

      return this.formatedSettings
    },
  },
}
</script>

<style lang="scss" scoped>
.settings-container {
  margin-left: -8px;
  margin-right: -9px;
}
.more-button {
  margin-top: auto;
  margin-bottom: auto;
}
</style>
