<template>
  <v-dialog :value="widget" max-width="85vw" @input="setDialogState">
    <v-card v-if="widget" class="pa-0 ma-0">
      <v-card-text class="pt-3">
        <ERow no-gutters>
          <iframe
            :src="iframeSource"
            class="widget-preview-frame"
            allowFullScreen="true"
            allow="clipboard-read; clipboard-write"
          ></iframe>
        </ERow>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="grey"
          data-test-id="close-credentials-dialog-btn"
          text
          @click="setDialogState(false)"
        >
          {{ $t("actions.close").toUpperCase() }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    widget: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    iframeStyle: {},
  }),
  computed: {
    iframeSource() {
      if (
        !this.widget?.id ||
        !this.widget?.widgetKey ||
        !this.widget?.widgetId
      ) {
        return
      }

      return `${this.$config.public.dashURL}/widgets?widgetKey=${this.widget.widgetKey}&widgetId=${this.widget.widgetId}&isWidget=true`
    },
  },
  methods: {
    setDialogState(event) {
      if (!event) {
        this.$emit("close")
      }
    },
  },
}
</script>

<style>
.widget-preview-frame {
  width: 100%;
  height: 75vh;
}
</style>
