<template>
  <div class="w-100">
    <v-textarea
      v-model="message"
      class="caption w-100"
      rows="3"
      outlined
      :hide-details="!errors"
      :auto-grow="autoGrow"
      v-bind="$attrs"
      :error-messages="errors ? errors : []"
      :success="success"
      :clearable="clearable"
      :validate-on="validateOn"
      @blur="() => $emit('input', message)"
    >
      <template #label>
        <div :class="labelClass">{{ label }}</div>
      </template>
      <template v-if="copyToClipboard" #append class="align-center">
        <CopyToClipboardBtn
          :text="message"
          icon
          iconType="fas fa-copy"
          @copied="$emit('copied')"
        />
      </template>
    </v-textarea>
  </div>
</template>

<script lang="ts">
import { PropType } from "vue"
import CopyToClipboardBtn from "@evercam/shared/components/CopyToClipboardBtn"
export type ValidateOnType =
  | "blur"
  | "input"
  | "submit"
  | "blur lazy"
  | "input lazy"
  | "submit lazy"
  | "lazy blur"
  | "lazy input"
  | "lazy submit"
  | "lazy"
export default {
  name: "Textarea",
  components: { CopyToClipboardBtn },
  props: {
    value: {
      type: String,
      default: "",
    },
    copyToClipboard: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
      default: "",
    },
    autoGrow: {
      type: Boolean,
      default: true,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    success: {
      type: Boolean,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    validateOn: {
      type: String as PropType<ValidateOnType>,
      default: "blur",
    },
  },
  data() {
    return {
      message: "",
    }
  },
  watch: {
    value: {
      handler(value) {
        this.message = value
      },
      immediate: true,
    },
  },
}
</script>
