<template>
  <v-dialog :value="widget" max-width="700px" @input="setDialogState">
    <v-card v-if="widget" class="pa-0 ma-0">
      <v-card-title class="title" data-test-id="widget-code-dialog-title">
        {{ $t("content.widgets.credentials") }}
        <v-spacer></v-spacer>
        <v-btn icon @click="setDialogState(false)">
          <v-icon color="primary" large> fas fa-times </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-3">
        <ERow :class="{ 'ml-1': $vuetify.breakpoint.mdAndUp }">
          <ECol class="caption mt-4">
            <v-tabs fixed-tabs>
              <v-tab key="Embedded"> Enbedded </v-tab>
              <v-tab-item key="Embedded" transition="none">
                <Textarea
                  :value="embedCode"
                  readonly
                  :copy-to-clipboard="true"
                  class="w-100 mt-5"
                  data-test-id="widget-embedded-code"
                  :label="$t('content.embed_code')"
                />
              </v-tab-item>

              <v-tab key="Iframe"> Iframe </v-tab>
              <v-tab-item key="Iframe" transition="none">
                <Textarea
                  :value="iframeCode"
                  readonly
                  :copy-to-clipboard="true"
                  class="w-100 mt-5"
                  :label="$t('content.widget_iframe')"
                />
              </v-tab-item>

              <v-tab v-if="widget.isPublic" key="Url"> Url </v-tab>
              <v-tab-item v-if="widget.isPublic" key="Url" transition="none">
                <Textarea
                  :value="widgetLink"
                  readonly
                  :copy-to-clipboard="true"
                  class="w-100 mt-5"
                  :label="$t('content.widget_url')"
                />
              </v-tab-item>
            </v-tabs>
          </ECol>
        </ERow>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="grey"
          data-test-id="close-credentials-dialog-btn"
          text
          @click="setDialogState(false)"
        >
          {{ $t("actions.close").toUpperCase() }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Textarea from "@evercam/shared/components/Textarea"

export default {
  components: {
    Textarea,
  },
  props: {
    widget: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    embedCode() {
      if (
        !this.widget?.id ||
        !this.widget?.widgetKey ||
        !this.widget?.widgetId
      ) {
        return ""
      }

      return `<div class="ec-container"><script src="${
        this.$config.public.dashURL ?? ""
      }/widget.js?widgetKey=${this.widget.widgetKey}&widgetId=${
        this.widget.widgetId // eslint-disable-next-line no-useless-escape
      }" async><\/script></div>`
    },
    iframeCode() {
      if (
        !this.widget?.id ||
        !this.widget?.widgetKey ||
        !this.widget?.widgetId
      ) {
        return ""
      }

      // eslint-disable-next-line no-useless-escape
      return `<iframe allow="fullscreen" sandbox="allow-scripts allow-same-origin" style="width: 100%; minHeight: 350px; height: 100%; border: none" src="${
        this.$config.public.dashURL ?? ""
      }/widgets?widgetKey=${this.widget.widgetKey}&widgetId=${
        this.widget.widgetId
      }&isWidget=true" />`
    },
    widgetLink() {
      if (
        !this.widget?.id ||
        !this.widget?.widgetKey ||
        !this.widget?.widgetId
      ) {
        return ""
      }

      // eslint-disable-next-line no-useless-escape
      return `${this.$config.public.dashURL ?? ""}/widgets?widgetKey=${
        this.widget.widgetKey
      }&widgetId=${this.widget.widgetId}&isWidget=true`
    },
  },
  methods: {
    setDialogState(event) {
      if (!event) {
        this.$emit("close")
      }
    },
  },
}
</script>
