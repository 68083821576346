<template>
  <ERow class="text-decoration-none ml-2" align="center">
    <NdaIcon v-if="isUnderNda" />
    <span class="subtitle">{{ value.name }}</span>
    <NuxtLink
      :to="
        isAdmin
          ? `/projects#project_dialog=true&project_exid=${value.exid}`
          : `/v2/projects/${value.exid}`
      "
      target="_blank"
    >
      <v-icon color="primary" class="mt-n1 ml-1" small>
        fas fa-external-link-alt
      </v-icon>
    </NuxtLink>
  </ERow>
</template>

<script>
import NdaIcon from "@/components/NdaIcon"
import { ProjectFeatureFlag } from "@evercam/shared/types/project"

export default {
  name: "ProjectRessource",
  components: {
    NdaIcon,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isUnderNda() {
      return this.value?.featureFlags?.includes(ProjectFeatureFlag.NdaMc)
    },
  },
}
</script>
